
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import GoBackButton from '../goBack/GoBackButton';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import AllHeaders from '../partials/AllHeaders';
import axios from 'axios';
import config from '../../config/config';
import { apiHeaderToken, apiHeaderTokenMultiPart } from '../../config/api_header';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { GetDesignationList } from "../slices/DesignationDropDown/designationDropDown";
import { useDispatch } from "react-redux";
import { FetchProjectListDropDown, FetchProjectLocationDropDown } from "../slices/ProjectListDropDown/ProjectListDropdownSlice";
import { toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import { useNavigate } from 'react-router-dom';
import { Col, Container, Nav, Spinner, Tab } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import WebSetting from './WebSetting';


const customStyles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: '#fff',
        borderColor: state.isFocused ? '#D2C9FF' : state.isHovered ? '#80CBC4' : provided.borderColor,
        boxShadow: state.isFocused ? '0 0 0 1px #D2C9FF' : 'none',
        '&:hover': {
            borderColor: '#D2C9FF',
        },
        height: '44px',
    }),
    menu: (provided) => ({
        ...provided,
        borderTop: '1px solid #D2C9FF',
    }),
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #D2C9FF',
        color: state.isSelected ? '#fff' : '#000',
        backgroundColor: state.isSelected ? '#4CAF50' : state.isFocused ? '#80CBC4' : provided.backgroundColor,
        '&:hover': {
            backgroundColor: '#80CBC4',
            color: '#fff',
        },
    }),
};
const customStylesLocation = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: '#fff',
        borderColor: state.isFocused ? '#D2C9FF' : state.isHovered ? '#80CBC4' : provided.borderColor,
        boxShadow: state.isFocused ? '0 0 0 1px #D2C9FF' : 'none',
        '&:hover': {
            borderColor: '#D2C9FF',
        },
        minHeight: '100px', // Increase height of the select box
        // width: '300px', // Increase width of the select box
        paddingLeft: '10px',
        textAlign: 'left',
    }),
    menu: (provided) => ({
        ...provided,
        borderTop: 'none',
    }),
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #D2C9FF',
        color: state.isSelected ? '#fff' : '#000',
        backgroundColor: state.isSelected ? '#4CAF50' : state.isFocused ? '#80CBC4' : provided.backgroundColor,
        '&:hover': {
            backgroundColor: '#80CBC4',
            color: '#fff',
        },
    }),
    multiValue: (provided) => ({
        ...provided,
        backgroundColor: '#29166F',
        borderRadius: '5px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        padding: '0 10px',
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: '#fff',
        fontSize: '14px',
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        color: '#fff',
        '&:hover': {
            backgroundColor: '#4CAF50', // Hover effect on the remove button
            color: '#fff',
        },
    }),
};



const SystemSetting = () => {

    return (
        <>
            <AllHeaders />
            <div className="maincontent">
                <div className="container hrdashboard" data-aos="fade-in" data-aos-duration="3000">
                    <GoBackButton />
                    <div className='dflexbtwn'>
                        <div className='sitecard w-100'>
                            <Container fluid>
                                <Row className="">
                                    <Col>
                                        <h4>System Settings</h4>
                                    </Col>
                                </Row>
                                <Tab.Container defaultActiveKey="web-setting">
                                    <Row>
                                        <Col md={3} className="bg-light p-3">
                                            <Nav variant="pills" className="flex-column gap-5">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="web-setting">Web Setting</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="address-setting">Address Setting</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="support-setting">Support Setting</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="smtp-mail-setting">SMTP Mail Setting</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="google-place-apis">Google Place APIs</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>

                                        <Col md={9} className="p-3">
                                            <Tab.Content>
                                                <Tab.Pane eventKey="web-setting">
                                                    <h5>Web Setting</h5>
                                                    <WebSetting />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="address-setting">
                                                    <h5>Address Setting</h5>
                                                    <p>Content for Address Setting goes here.</p>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="support-setting">
                                                    <h5>Other Setting</h5>
                                                    <p>Content for Other Setting goes here.</p>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="smtp-mail-setting">
                                                    <h5>Delivery Boy Setting</h5>
                                                    <p>Content for Delivery Boy Setting goes here.</p>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="google-place-apis">
                                                    <h5>App Setting</h5>
                                                    <p>Content for App Setting goes here.</p>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </Container>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SystemSetting;
