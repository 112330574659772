import React, { useState } from "react";
import { Button, Col, Row, Form, InputGroup } from "react-bootstrap";
import { FiGlobe } from "react-icons/fi";
import { useDropzone } from "react-dropzone";
import ReactQuill from "react-quill";


const WebSetting = () => {

    const [selectedFile, setSelectedFile] = useState(null);

    const onDrop = (acceptedFiles) => {
      const file = acceptedFiles[0];
      setSelectedFile(file);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      accept: "image/*",
      maxFiles: 1, 
    });
  

    return (
        <>
            <Col className="p-3">
                <Form>
                    <Row className="mb-3">
                        <Col md={6}>
                            <Form.Group controlId="address">
                                <Form.Label>Site Title</Form.Label>
                                <Form.Control type="text" placeholder="Site Title" />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="website-url">
                                <Form.Label>Website URL</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter website URL"
                                        aria-label="Website URL"
                                    />
                                    <InputGroup.Text>
                                        <FiGlobe />
                                    </InputGroup.Text>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col md={6}>
                            <Form.Group controlId="meta-description">
                                <Form.Label>Meta Title</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={4}
                                    placeholder="Enter meta title"
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="meta-description">
                                <Form.Label>Meta Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={4}
                                    placeholder="Enter meta description"
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col md={4}>
                            <Form.Group controlId="web-logo">
                                <Form.Label>Web Logo</Form.Label>
                                <div
                                    {...getRootProps()}
                                    style={{
                                        border: "2px dashed #007bff",
                                        padding: "20px",
                                        borderRadius: "5px",
                                        textAlign: "center",
                                        background: isDragActive ? "#e9ecef" : "#fff",
                                        cursor: "pointer",
                                    }}
                                >
                                    <input {...getInputProps()} />
                                    {isDragActive ? (
                                        <p>Drop the file here...</p>
                                    ) : (
                                        <p>Drag and drop a file here, or click to select</p>
                                    )}
                                </div>
                                {selectedFile && (
                                    <Form.Text className="text-muted mt-2">
                                        Selected File: {selectedFile.name}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group controlId="web-logo">
                                <Form.Label>Favicon Icon</Form.Label>
                                <div
                                    {...getRootProps()}
                                    style={{
                                        border: "2px dashed #007bff",
                                        padding: "20px",
                                        borderRadius: "5px",
                                        textAlign: "center",
                                        background: isDragActive ? "#e9ecef" : "#fff",
                                        cursor: "pointer",
                                    }}
                                >
                                    <input {...getInputProps()} />
                                    {isDragActive ? (
                                        <p>Drop the file here...</p>
                                    ) : (
                                        <p>Drag and drop a file here, or click to select</p>
                                    )}
                                </div>
                                {selectedFile && (
                                    <Form.Text className="text-muted mt-2">
                                        Selected File: {selectedFile.name}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group controlId="web-logo">
                                <Form.Label>Placeholder Image</Form.Label>
                                <div
                                    {...getRootProps()}
                                    style={{
                                        border: "2px dashed #007bff",
                                        padding: "20px",
                                        borderRadius: "5px",
                                        textAlign: "center",
                                        background: isDragActive ? "#e9ecef" : "#fff",
                                        cursor: "pointer",
                                    }}
                                >
                                    <input {...getInputProps()} />
                                    {isDragActive ? (
                                        <p>Drop the file here...</p>
                                    ) : (
                                        <p>Drag and drop a file here, or click to select</p>
                                    )}
                                </div>
                                {selectedFile && (
                                    <Form.Text className="text-muted mt-2">
                                        Selected File: {selectedFile.name}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    
                    <Button variant="success text-center" className="mt-3">
                        Add Web Setting
                    </Button>
                </Form>
            </Col>
        </>
    )
}

export default WebSetting